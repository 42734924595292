export const API_ROUTES = {
  objects: "/objects",
  projects: "/projects",
  "system-groups": "/system-groups",
  systems: "/systems",
  locations: "/locations",
  equipments: "/equipments",
  sections: "/sections",
  cables: "/cables",
  "cable-type": "/cable-types",
  "cable-bundles": "/cable-bundles",
  "route-points": "/route-points",
  drums: "/drums",
  orders: "/orders",
  types: "/types",
};
