import compose from "compose-function";
import { patch, post } from "../api/api";
import { sanitizeEntity } from "./../utils/dao";
import { getInlineEditQueryParam } from "../utils/utils";
import { API_ROUTES } from "../enums/api";

const API_ROUTE = API_ROUTES.equipments;

/**
 * Update an equipment
 *
 * @param {object[]} equipment
 * @param {string} equipmentId
 * @returns {object} - response
 * {@link https://anypoint.mulesoft.com/exchange/e352dc95-23bc-4542-a357-94cbe82019f1/e-oasis-api/minor/1.4/console/method/%235701/}
 */
export async function patchEquipment(equipment, equipmentId, isInlineEdit) {
  return patch(
    [API_ROUTE, equipmentId].join("/") + getInlineEditQueryParam(isInlineEdit),
    compose(
      sanitizeEntity("id"),
      sanitizeEntity("object_number"),
      sanitizeEntity("system_group_number"),
      sanitizeEntity("system_number"),
      sanitizeEntity("reference"),
      sanitizeEntity("reference2"),
      sanitizeEntity("project_number"),
      sanitizeEntity("location_number")
    )(equipment)
  );
}

/**
 * Create an equipment
 *
 * @param {object[]} equipment
 * @returns {object} - response
 * {@link https://anypoint.mulesoft.com/exchange/e352dc95-23bc-4542-a357-94cbe82019f1/e-oasis-api/minor/1.4/console/method/%235436/}
 */
export async function addEquipment(equipment) {
  const body = await post(
    API_ROUTE,
    compose(sanitizeEntity("system_group_number"))(equipment)
  );
  return body?.response;
}
