import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";
import EditModes from "../enums/EditModes";
import useUserPermissionsContext from "../hooks/useUserPermissionsContext";
import UserRights from "../enums/UserRights";
import { FILTER_TYPES } from "../dao/types";
import useDrum from "../hooks/useDrum";
import useFields from "../hooks/useFields";
import { create, softDelete, update } from "../dao/operations";
import { API_ROUTES } from "../enums/api";
import { ROUTES } from "../enums/Routes";
import { Box, CircularProgress, Paper, Typography } from "@mui/material";
import CreateItemButton from "../components/CreateItemButton";
import EditEntity from "../components/EditEntity";
import { addSearchParams } from "../utils/dao";
import EntityOverview from "./EntityOverview";
import { AssignModal } from "../components/AssignModal";
import { useSearchParams } from "../hooks/useSearchParams";

const API_ROUTE = API_ROUTES["drums"];

const DrumDetail = ({ title = "", mode = "" }) => {
  const [assignCableModel, setAssignCableModel] = useState({ open: false });
  const history = useHistory();

  const { drumId } = useParams();
  const { objectId } = useSearchParams();

  const drumParams = {
    objectId,
    drumId,
  };

  const isUpdateMode = mode === EditModes.UPDATE;
  const isCreateMode = mode === EditModes.CREATE;

  const { isAllowedTo, isLoading } = useUserPermissionsContext();

  const isAllowedToUpdate = isAllowedTo(UserRights.UPDATE, FILTER_TYPES.drum);
  const isAllowedToDelete = isAllowedTo(
    UserRights.SOFT_DELETE,
    FILTER_TYPES.drum
  );
  const isAllowedToCreate = isAllowedTo(UserRights.CREATE, FILTER_TYPES.drum);
  const isAllowedToAssign = isAllowedTo(UserRights.ASSIGN_CABLE);

  const handleAssignment = (state) => {
    setAssignCableModel({ ...state, open: state.open });
  };

  const {
    drum,
    fetchDrumData,
    isLoading: recordIsLoading,
  } = useDrum(drumParams);

  const [fields] = useFields(
    FILTER_TYPES.drum,
    false,
    isCreateMode,
    isUpdateMode
  );

  const searchParams = useMemo(() => {
    return new URLSearchParams(
      Object.entries({ objectId: drum.object_id }).filter(([, value]) => value)
    ).toString();
  }, [drum.object_id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function storeDrum(payload) {
    if (isUpdateMode) {
      // Existing drum
      await update({ ...payload, id: drum.id }, { route: API_ROUTE }).then(
        () => {
          fetchDrumData(drumParams);
        }
      );
    } else {
      // Brand new drum
      return create(payload, { route: API_ROUTE }).then((res) => {
        history.push(["/drum", res.id].join("/"));
      });
    }
  }

  async function removeDrum(payload) {
    if (isUpdateMode) {
      return softDelete({ route: API_ROUTE, delete: true })(payload).then(
        () => {
          fetchDrumData(drumParams);
        }
      );
    }
  }

  async function cancelChanges() {
    if (isUpdateMode) {
      fetchDrumData(drumParams);
    } else {
      history.goBack();
    }
  }

  function restoreDeletedDrum(payload) {
    return softDelete({ route: API_ROUTE, delete: false })(payload).then(() => {
      fetchDrumData(drumParams);
    });
  }

  return !fields || recordIsLoading ? (
    <Box
      sx={{
        marginTop: 3,
        textAlign: "center",
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Paper
      sx={{
        padding: 4,
      }}
    >
      <Box
        sx={{
          marginBottom: "1rem",
          justifyContent: "space-between",
          display: "flex",
        }}
      >
        <Typography variant='h5'>{title}</Typography>
        {isUpdateMode && (
          <CreateItemButton
            size='medium'
            entity={FILTER_TYPES.drum}
            to={addSearchParams(ROUTES.drums.create)(searchParams)}
            isDisabled={!isAllowedToCreate}
          />
        )}
      </Box>
      {!isLoading && (
        <EditEntity
          {...{
            isAllowedToUpdate,
            isAllowedToDelete,
            isAllowedToCreate,
            entity: drum,
            fields,
            objectId,
            storeEntity: storeDrum,
            deleteEntity: removeDrum,
            entityType: FILTER_TYPES.drum,
            cancel: cancelChanges,
            restoreEntity: restoreDeletedDrum,
            isUpdateMode,
          }}
        />
      )}
      {isUpdateMode && (
        <EntityOverview
          entity={FILTER_TYPES.cable}
          title={"Cables overview"}
          nestedEntity={true}
          parent={{
            object_id: drum.object_id,
            drum_id: drumId,
            hasAssignButtons: isAllowedToAssign,
            options: {
              handleAssignment,
              assignCableModel,
            },
            entity: { ...drum, entityName: FILTER_TYPES.drum },
          }}
        />
      )}
      {isAllowedToAssign && (
        <AssignModal
          open={assignCableModel.open}
          onClose={() => handleAssignment({ open: false })}
          modalOptions={{
            entity: FILTER_TYPES.cable,
            title: "Assign cables to the drum",
            parent: {
              entity: { ...drum, entityName: FILTER_TYPES.drum },
              object_id: drum.object_id,
              project_id: drum.project_id,
              hasAssignButtons: true,
              options: {
                handleAssignment,
              },
            },
          }}
        />
      )}
    </Paper>
  );
};

export default DrumDetail;
