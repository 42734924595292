import compose from "compose-function";
import { patch, post } from "../api/api";
import { sanitizeEntity } from "./../utils/dao";
import { getInlineEditQueryParam } from "../utils/utils";
import { API_ROUTES } from "../enums/api";

const API_ROUTE = API_ROUTES.locations;

export async function patchLocation(location, locationId, isInlineEdit) {
  return patch(
    [API_ROUTE, locationId].join("/") + getInlineEditQueryParam(isInlineEdit),
    compose(
      sanitizeEntity("object_number"),
      sanitizeEntity("section_number")
    )(location)
  );
}

export async function addLocation(location) {
  const body = await post(API_ROUTE, location);
  return body?.response;
}
