import { useEffect, useMemo } from "react";
import { CircularProgress, Paper, Typography } from "@mui/material";
import { useHistory, useParams } from "react-router";
import log from "loglevel";
import { Box } from "@mui/system";

import useFields, { FILTER_TYPES } from "../hooks/useFields";

import useProject from "../hooks/useProject";

import EditEntity from "../components/EditEntity";
import CreateItemButton from "../components/CreateItemButton";

import { patchProject, addProject } from "../dao/projects";

import { softDelete } from "../dao/operations";

import useUserPermissionsContext from "../hooks/useUserPermissionsContext";
import UserRights from "../enums/UserRights";
import EditModes from "../enums/EditModes";
import { ROUTES } from "../enums/Routes";
import { API_ROUTES } from "../enums/api";
import { useSearchParams } from "../hooks/useSearchParams";
import { addSearchParams } from "../utils/dao";

const API_ROUTE = API_ROUTES["projects"];

export default function ProjectDetail({ title = "", mode = "" }) {
  const history = useHistory();

  // update record only
  // get equipmentId from the search params
  const { projectId } = useParams();

  // create record only
  // get objectId from the search params
  const { objectId } = useSearchParams();

  const isUpdateMode = mode === EditModes.UPDATE;
  const isCreateMode = mode === EditModes.CREATE;

  const { isAllowedTo, isLoading } = useUserPermissionsContext();

  const isAllowedToCreate = isAllowedTo(
    UserRights.CREATE,
    FILTER_TYPES.project
  );

  const isAllowedToUpdate = isAllowedTo(
    UserRights.UPDATE,
    FILTER_TYPES.project
  );

  const isAllowedToDelete = isAllowedTo(
    UserRights.SOFT_DELETE,
    FILTER_TYPES.project
  );

  const {
    project,
    fetchProjectData,
    isLoading: recordIsLoading,
  } = useProject(objectId, projectId);

  const [fields] = useFields(
    FILTER_TYPES.project,
    false,
    isCreateMode,
    isUpdateMode
  );

  const searchParams = useMemo(() => {
    return new URLSearchParams(
      Object.entries({ objectId: project.object_id }).filter(
        ([, value]) => value
      )
    ).toString();
  }, [project.object_id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function storeProject(payload) {
    if (isUpdateMode) {
      // Existing project
      log.trace("about to patch", project.id);
      await patchProject(payload, project.id).then(() => {
        fetchProjectData();
      });
    } else {
      // Brand new project
      log.trace("about to create", JSON.stringify(project));
      return addProject(payload).then((res) => {
        history.push(["/project", res.id].join("/"));
      });
    }
  }

  async function removeProject(payload) {
    if (isUpdateMode) {
      return softDelete({
        route: API_ROUTE,
        delete: true,
      })(payload).then(() => {
        fetchProjectData();
      });
    }
  }

  async function cancelChanges() {
    if (isUpdateMode) {
      fetchProjectData();
    } else {
      history.goBack();
    }
  }

  function restoreDeletedProject(payload) {
    softDelete({
      route: API_ROUTE,
      delete: false,
    })(payload).then(() => {
      fetchProjectData();
    });
  }

  return !fields || recordIsLoading ? (
    <Box
      sx={{
        marginTop: 3,
        textAlign: "center",
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Paper
      sx={{
        padding: 4,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "1rem",
        }}
      >
        <Typography variant='h5'>{title}</Typography>
        {isUpdateMode && (
          <CreateItemButton
            size='medium'
            entity={FILTER_TYPES.project}
            to={addSearchParams(ROUTES.project.create)(searchParams)}
            isDisabled={isAllowedToCreate === false}
          />
        )}
      </Box>
      {isLoading === false && (
        <EditEntity
          {...{
            isAllowedToUpdate,
            isAllowedToDelete,
            isAllowedToCreate,
            entity: project,
            fields,
            objectId,
            storeEntity: storeProject,
            deleteEntity: removeProject,
            entityType: FILTER_TYPES.project,
            cancel: cancelChanges,
            restoreEntity: restoreDeletedProject,
            isUpdateMode,
          }}
        />
      )}
    </Paper>
  );
}
