import compose from "compose-function";
import { patch, post } from "../api/api";
import { sanitizeEntity } from "./../utils/dao";
import { getInlineEditQueryParam } from "../utils/utils";
import { API_ROUTES } from "../enums/api";

const API_ROUTE = API_ROUTES.systems;

export async function patchSystem(system, systemId, isInlineEdit) {
  return patch(
    [API_ROUTE, systemId].join("/") + getInlineEditQueryParam(isInlineEdit),
    compose(
      sanitizeEntity("id"),
      sanitizeEntity("object_number"),
      sanitizeEntity("system_group_number")
    )(system)
  );
}

export async function addSystem(system) {
  const body = await post(API_ROUTE, system);
  return body?.response;
}
