import compose from "compose-function";
import { patch, post } from "../api/api";
import { sanitizeEntity } from "../utils/dao";
import { getInlineEditQueryParam } from "../utils/utils";
import { API_ROUTES } from "../enums/api";

const API_ROUTE = API_ROUTES["system-groups"];

export async function patchSystemGroup(
  systemGroup,
  systemGroupId,
  isInlineEdit
) {
  return patch(
    [API_ROUTE, systemGroupId].join("/") +
      getInlineEditQueryParam(isInlineEdit),
    compose(sanitizeEntity("id"), sanitizeEntity("object_number"))(systemGroup)
  );
}
export async function addSystemGroup(systemGroup) {
  const body = await post(API_ROUTE, systemGroup);
  return body?.response;
}

/**
 * Sends POST request to backend
 * for copying an SystemGroup item
 * @param {Object} systemGroup
 * @param {Number} newSystemGroupNumber
 * @returns {Promise}
 * {@link https://anypoint.mulesoft.com/exchange/e352dc95-23bc-4542-a357-94cbe82019f1/e-oasis-api/minor/1.1/console/method/%233911/}
 */
export async function copySystemGroup(
  systemGroup = {},
  newSystemGroupNumber = 0
) {
  const { id } = systemGroup;
  return post([API_ROUTE, id, "copy"].join("/"), {
    number: newSystemGroupNumber,
  });
}
