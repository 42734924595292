import compose from "compose-function";
import { get, patch, post } from "../api/api";
import { sanitizeEntity } from "../utils/dao";
import { getInlineEditQueryParam } from "../utils/utils";
import { API_ROUTES } from "../enums/api";

const API_ROUTE = API_ROUTES.projects;
export const PROJECT_COLUMNS_API_PATH = "/fields?type=project";

export async function patchProject(project, projectId, isInlineEdit) {
  const body = await patch(
    [API_ROUTE, projectId].join("/") + getInlineEditQueryParam(isInlineEdit),
    compose(
      sanitizeEntity("id"),
      sanitizeEntity("object_number"),
      sanitizeEntity("reference"),
      sanitizeEntity("client")
    )(project)
  );
  return body?.response;
}

export async function fetchProjectTableColumns() {
  const body = await get(PROJECT_COLUMNS_API_PATH);
  return body?.response;
}

export async function addProject(project) {
  const body = await post(API_ROUTE, project);
  return body?.response;
}
