import compose from "compose-function";
import { patch, post } from "../api/api";
import { sanitizeEntity } from "../utils/dao";
import { getInlineEditQueryParam } from "../utils/utils";
import { API_ROUTES } from "../enums/api";
const API_ROUTE = API_ROUTES.cables;

/**
 * Create a cable
 *
 * @param {object[]} cable
 * @returns {object} - response
 * {@link https://anypoint.mulesoft.com/exchange/e352dc95-23bc-4542-a357-94cbe82019f1/e-oasis-api/minor/1.4/console/method/%233162/ }
 */
export async function addCable(cable) {
  const body = await post(API_ROUTE, cable);
  return body?.response;
}

/**
 * Update a cable
 *
 * @param {object[]} cable
 * @returns {object} - response
 * {@link https://anypoint.mulesoft.com/exchange/e352dc95-23bc-4542-a357-94cbe82019f1/e-oasis-api/minor/1.4/console/method/%233427/}
 */
export async function patchCable(cable, cableId, isInlineEdit) {
  return patch(
    [API_ROUTE, cableId].join("/") + getInlineEditQueryParam(isInlineEdit),
    compose(
      sanitizeEntity("id"),
      sanitizeEntity("source_destination"),
      sanitizeEntity("object_number"),
      sanitizeEntity("project_reference"),
      sanitizeEntity("system_group_number"),
      sanitizeEntity("system_number"),
      sanitizeEntity("source_section_location"),
      sanitizeEntity("destination_section_location"),
      sanitizeEntity("is_deleted")
    )(cable)
  );
}
