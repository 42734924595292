import { patch, post } from "../api/api";
import { getInlineEditQueryParam } from "../utils/utils";
import { API_ROUTES } from "../enums/api";

const API_ROUTE = API_ROUTES["route-points"];
/**
 * patchRoutePoint
 * @param {Object} payload
 * @param {string} id
 * @param {boolean} isInlineEdit
 * @returns
 */
export async function patchRoutePoint(payload, id, isInlineEdit) {
  return patch(
    `${API_ROUTE}/${id}` + getInlineEditQueryParam(isInlineEdit),
    payload
  );
}

export async function addRoutePoint(object) {
  const body = await post(API_ROUTE, object);
  return body?.response;
}

/**
 * Sends POST request to backend
 * for copying an Route point item
 * @param {Object} object
 * @param {Number} newObjectNumber
 * @returns {Promise}
 * {@link https://anypoint.mulesoft.com/exchange/e352dc95-23bc-4542-a357-94cbe82019f1/e-oasis-api/minor/1.1/console/method/%232018/}
 */
export async function copyRoutePoint(object = {}, newObjectNumber = 0) {
  return post([API_ROUTE, object.id, "copy"].join("/"), {
    number: newObjectNumber,
  });
}
