import { useEffect, useState, useMemo } from "react";
import { CircularProgress, Paper, Typography, Button } from "@mui/material";
import { useHistory, useParams } from "react-router";
import log from "loglevel";
import { Box } from "@mui/system";

import useFields, { FILTER_TYPES } from "../hooks/useFields";
import useEquipment from "../hooks/useEquipment";
import useUserPermissionsContext from "../hooks/useUserPermissionsContext";

import EditEntity from "../components/EditEntity";

import EntityOverview from "../views/EntityOverview";

import { addEquipment, patchEquipment } from "../dao/equipments";

import { softDelete } from "../dao/operations";

import { exportRecords } from "../dao/common";
import { exportToFile } from "../utils/utils";
import { errorMessageHandler } from "../utils/error";

import UserRights from "../enums/UserRights";
import ButtonWithSpinner from "../components/ButtonWithSpinner";

import CreateItemButton from "../components/CreateItemButton";
import EditModes from "../enums/EditModes";
import { ROUTES } from "../enums/Routes";
import { API_ROUTES } from "../enums/api";
import { useSearchParams } from "../hooks/useSearchParams";
import { addSearchParams } from "../utils/dao";

const IRIS_TITLE = "Iris issues";
const EXPORT_BUTTON_LABEL = "Export Cable list";
const API_ROUTE = API_ROUTES["equipments"];

export default function EquipmentDetail({ title = "", mode = "" }) {
  const [isExportingCableList, setIsExportingCableList] = useState(false);
  const history = useHistory();

  // update record only
  // get equipmentId from the search params
  const { equipmentId } = useParams();

  // create record only
  // get fields from the search params
  const {
    objectId,
    systemGroupId,
    systemId,
    locationId,
    projectId,
    sectionReference,
  } = useSearchParams();

  const { isAllowedTo, isLoading } = useUserPermissionsContext();

  const isUpdateMode = mode === EditModes.UPDATE;
  const isCreateMode = mode === EditModes.CREATE;

  const isAllowedToCreate = isAllowedTo(
    UserRights.CREATE,
    FILTER_TYPES.equipment
  );

  const isAllowedToUpdate = isAllowedTo(
    UserRights.UPDATE,
    FILTER_TYPES.equipment
  );

  const isAllowedToDelete = isAllowedTo(
    UserRights.SOFT_DELETE,
    FILTER_TYPES.equipment
  );

  const {
    equipment,
    fetchEquipmentData,
    isLoading: recordIsLoading,
  } = useEquipment({
    objectId,
    systemGroupId,
    systemId,
    locationId,
    equipmentId,
    projectId,
    sectionReference,
  });

  const [fields] = useFields(
    FILTER_TYPES.equipment,
    false,
    isCreateMode,
    isUpdateMode
  );

  const searchParams = useMemo(() => {
    return new URLSearchParams(
      Object.entries({
        objectId: equipment.object_id,
        systemGroupId: equipment.system_group_id,
        systemId: equipment.system_id,
        locationId: equipment.location_id,
        projectId: equipment.project_id,
      }).filter(([, value]) => value)
    ).toString();
  }, [
    equipment.object_id,
    equipment.system_group_id,
    equipment.system_id,
    equipment.location_id,
    equipment.project_id,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function storeEquipment(payload) {
    if (isUpdateMode) {
      // Existing Equipment
      log.trace("about to patch", equipment.id);
      await patchEquipment(payload, equipment.id).then(() => {
        if (
          objectId !== payload.object_id ||
          systemId !== payload.system_id ||
          systemGroupId !== payload.system_group_id
        ) {
          history.push(["/equipment", equipment.id].join("/"));
        } else {
          fetchEquipmentData();
        }
      });
    } else {
      // Brand new Equipment
      log.trace("about to create", JSON.stringify(payload));
      return addEquipment(payload).then((res) => {
        history.push(["/equipment", res.id].join("/"));
      });
    }
  }

  async function removeEquipment(payload) {
    if (isUpdateMode) {
      return softDelete({ route: API_ROUTE, delete: true })(payload).then(
        () => {
          fetchEquipmentData();
        }
      );
    }
  }

  async function cancelChanges() {
    if (isUpdateMode) {
      fetchEquipmentData();
    } else {
      history.goBack();
    }
  }

  function restoreDeletedEquipment(payload) {
    softDelete({ route: API_ROUTE, delete: false })(payload).then(() => {
      fetchEquipmentData();
    });
  }

  const handleExportCableListClick = () => {
    setIsExportingCableList(true);
    const abortController = new AbortController();
    exportRecords(
      `/reports/cable`,
      {
        equipment_id: equipmentId,
      },
      abortController,
      "application/json"
    )
      .then((res) => {
        exportToFile(
          `Cables connected report - ${equipmentId}.pdf`,
          res,
          "application/pdf"
        );
      })
      .catch((err) => {
        errorMessageHandler(err);
      })
      .finally(() => {
        setIsExportingCableList(false);
      });
  };

  return !fields || recordIsLoading ? (
    <Box
      sx={{
        marginTop: 3,
        textAlign: "center",
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Paper
      sx={{
        padding: 4,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "1rem",
        }}
      >
        <Typography variant='h5'>{title}</Typography>
        {isUpdateMode && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "1rem",
            }}
          >
            <CreateItemButton
              size='medium'
              entity={FILTER_TYPES.equipment}
              to={addSearchParams(ROUTES.equipment.create)(searchParams)}
              isDisabled={isAllowedToCreate === false}
            />
            {/* Only show Iris link on edit screen and when we have an id */}
            {equipment.iris_id && (
              <Button
                href={[
                  process.env.REACT_APP_IRIS_HOST,
                  "Bakker/IRIS/relations",
                  equipment.iris_id,
                ].join("/")}
                title={IRIS_TITLE}
                sx={{
                  padding: "4px 16px",
                }}
                variant='outlined'
                target='_blank'
              >
                {IRIS_TITLE}
              </Button>
            )}
          </Box>
        )}
      </Box>
      {isLoading === false && (
        <>
          <EditEntity
            {...{
              isAllowedToUpdate,
              isAllowedToDelete,
              isAllowedToCreate,
              entity: equipment,
              fields,
              storeEntity: storeEquipment,
              deleteEntity: removeEquipment,
              entityType: FILTER_TYPES.equipment,
              cancel: cancelChanges,
              restoreEntity: restoreDeletedEquipment,
              isUpdateMode,
            }}
          />
          {isUpdateMode && (
            <>
              <ButtonWithSpinner
                onClick={handleExportCableListClick}
                variant='text'
                color='primary'
                title={EXPORT_BUTTON_LABEL}
                loading={isExportingCableList}
                disabled={isExportingCableList}
              >
                {EXPORT_BUTTON_LABEL}
              </ButtonWithSpinner>
              <EntityOverview
                entity={FILTER_TYPES.section}
                title={"Sections overview"}
                nestedEntity={true}
                parent={{
                  object_id: equipment.object_id,
                  system_group_id: equipment.system_group_id,
                  system_id: equipment.system_id,
                  equipment_id: equipment.id,
                }}
              />
            </>
          )}
        </>
      )}
    </Paper>
  );
}
