import compose from "compose-function";
import { patch, post } from "../api/api";
import { sanitizeEntity } from "../utils/dao";
import { getInlineEditQueryParam } from "../utils/utils";
import { API_ROUTES } from "../enums/api";

const API_ROUTE = API_ROUTES.sections;

/**
 * Update an section
 *
 * @param {object[]} section
 * @returns {object} - response
 * {@link https://anypoint.mulesoft.com/exchange/e352dc95-23bc-4542-a357-94cbe82019f1/e-oasis-api/minor/1.2/console/method/%238832/}
 */
export async function patchSection(section, sectionId, isInlineEdit) {
  return patch(
    [API_ROUTE, sectionId].join("/") + getInlineEditQueryParam(isInlineEdit),
    compose(
      sanitizeEntity("id"),
      sanitizeEntity("equipment_reference2"),
      sanitizeEntity("reference"),
      sanitizeEntity("system_group_number"),
      sanitizeEntity("system_group_id"),
      sanitizeEntity("system_number"),
      sanitizeEntity("system_id"),
      sanitizeEntity("equipment_reference"),
      sanitizeEntity("object_number"),
      sanitizeEntity("pom_id"),
      sanitizeEntity("imported"),
      sanitizeEntity("imported_by"),
      sanitizeEntity("updated"),
      sanitizeEntity("updated_by")
    )(section)
  );
}

/**
 * Create a section
 *
 * @param {object[]} section
 * @returns {object} - response
 * {@link https://anypoint.mulesoft.com/exchange/e352dc95-23bc-4542-a357-94cbe82019f1/e-oasis-api/minor/1.4/console/method/%2312547/}
 */
export async function addSection(section) {
  const body = await post(API_ROUTE, section);
  return body?.response;
}
