import { useEffect, useMemo, useState } from "react";
import { CircularProgress, Paper, Typography } from "@mui/material";
import { useHistory, useParams } from "react-router";
import { Box } from "@mui/system";

import useFields, { FILTER_TYPES } from "../hooks/useFields";

import useCableBundle from "../hooks/useCableBundle";

import EditEntity from "../components/EditEntity";

import EntityOverview from "../views/EntityOverview";

import useUserPermissionsContext from "../hooks/useUserPermissionsContext";
import UserRights from "../enums/UserRights";

import CreateItemButton from "../components/CreateItemButton";
import EditModes from "../enums/EditModes";
import { ROUTES } from "../enums/Routes";
import { API_ROUTES } from "../enums/api";
import { addSearchParams } from "../utils/dao";
import { useSearchParams } from "../hooks/useSearchParams";
import { addCableBundle, patchCableBundle } from "../dao/cableBundles";
import { softDelete } from "../dao/operations";
import { AssignModal } from "../components/AssignModal";

const API_ROUTE = API_ROUTES["cable-bundles"];

export default function CableBundleDetail({ title = "", mode = "" }) {
  const [assignCableModel, setAssignCableModel] = useState({ open: false });
  const history = useHistory();

  const { id } = useParams();

  const { objectId } = useSearchParams();

  const cableBundleParams = {
    objectId,
    cableBundleId: id,
  };

  const isUpdateMode = mode === EditModes.UPDATE;
  const isCreateMode = mode === EditModes.CREATE;

  const { isAllowedTo, isLoading } = useUserPermissionsContext();

  const isAllowedToUpdate = isAllowedTo(
    UserRights.UPDATE,
    FILTER_TYPES.cable_bundle
  );
  const isAllowedToDelete = isAllowedTo(
    UserRights.SOFT_DELETE,
    FILTER_TYPES.cable_bundle
  );
  const isAllowedToCreate = isAllowedTo(
    UserRights.CREATE,
    FILTER_TYPES.cable_bundle
  );

  const isAllowedToAssign = isAllowedTo(UserRights.ASSIGN_CABLE);

  const {
    cableBundle,
    fetchCableBundleData,
    isLoading: recordIsLoading,
  } = useCableBundle(cableBundleParams);

  const [fields] = useFields(
    FILTER_TYPES.cable_bundle,
    false,
    isCreateMode,
    isUpdateMode
  );

  const searchParams = useMemo(() => {
    return new URLSearchParams(
      Object.entries({ objectId: cableBundle.object_id }).filter(
        ([, value]) => value
      )
    ).toString();
  }, [cableBundle.object_id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function storeCableBundle(payload) {
    if (isUpdateMode) {
      // Existing bundle
      await patchCableBundle(payload, cableBundle.id).then(() => {
        fetchCableBundleData(cableBundleParams);
      });
    } else {
      // Brand new bundle
      return addCableBundle(payload).then((res) => {
        history.push(ROUTES.bundle.edit.replace(":id", res.id));
      });
    }
  }

  async function removeCableBundle(payload) {
    if (isUpdateMode) {
      return softDelete({ route: API_ROUTE, delete: true })(payload).then(
        () => {
          fetchCableBundleData(cableBundleParams);
        }
      );
    }
  }

  async function cancelChanges() {
    if (isUpdateMode) {
      fetchCableBundleData(cableBundleParams);
    } else {
      history.goBack();
    }
  }

  async function restoreDeletedCableBundle(payload) {
    return softDelete({ route: API_ROUTE, delete: false })(payload).then(() => {
      fetchCableBundleData(cableBundleParams);
    });
  }

  const handleAssignment = (state) => {
    setAssignCableModel({ ...state, open: state.open });
  };

  return !fields || recordIsLoading ? (
    <Box
      sx={{
        marginTop: 3,
        textAlign: "center",
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Paper
      sx={{
        padding: 4,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "1rem",
        }}
      >
        <Typography variant='h5'>{title}</Typography>
        {isUpdateMode && (
          <CreateItemButton
            size='medium'
            entity={"bundle"}
            to={addSearchParams(ROUTES.bundle.create)(searchParams)}
            isDisabled={isAllowedToCreate === false}
          />
        )}
      </Box>
      {isLoading === false && (
        <EditEntity
          {...{
            isAllowedToUpdate,
            isAllowedToDelete,
            isAllowedToCreate,
            entity: cableBundle,
            fields,
            storeEntity: storeCableBundle,
            deleteEntity: removeCableBundle,
            entityType: FILTER_TYPES.cable_bundle,
            cancel: cancelChanges,
            restoreEntity: restoreDeletedCableBundle,
            isUpdateMode,
          }}
        />
      )}
      {isUpdateMode && (
        <EntityOverview
          entity={FILTER_TYPES.cable}
          title={"Cables overview"}
          nestedEntity={true}
          parent={{
            object_id: cableBundle.object_id,
            cable_bundle_id: cableBundle.id,
            hasAssignButtons: isAllowedToAssign,
            options: {
              handleAssignment,
              assignCableModel,
            },
            entity: { ...cableBundle, entityName: FILTER_TYPES.cable_bundle },
          }}
        />
      )}
      {isAllowedToAssign && (
        <AssignModal
          open={assignCableModel.open}
          onClose={() => handleAssignment({ open: false })}
          modalOptions={{
            entity: FILTER_TYPES.cable,
            title: "Assign cables to the cable bundle",
            parent: {
              entity: { ...cableBundle, entityName: FILTER_TYPES.cable_bundle },
              object_id: cableBundle.object_id,
              hasAssignButtons: true,
              options: {
                handleAssignment,
              },
            },
          }}
        />
      )}
    </Paper>
  );
}
