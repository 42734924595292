import { useEffect } from "react";
import { CircularProgress, Paper, Typography } from "@mui/material";
import { useHistory, useParams } from "react-router";
import { Box } from "@mui/system";

import useFields, { FILTER_TYPES } from "../hooks/useFields";

import useCableType from "../hooks/useCableType";

import EditEntity from "../components/EditEntity";

import useUserPermissionsContext from "../hooks/useUserPermissionsContext";
import UserRights from "../enums/UserRights";

import CreateItemButton from "../components/CreateItemButton";
import EditModes from "../enums/EditModes";
import { ROUTES } from "../enums/Routes";
import { API_ROUTES } from "../enums/api";
import { create, update, softDelete } from "../dao/operations";

const API_ROUTE = API_ROUTES["cable-type"];

export default function CableTypeDetail({ title = "", mode = "" }) {
  const history = useHistory();

  const { cableTypeId } = useParams();

  const cableTypeParams = {
    cableTypeId,
  };

  const isUpdateMode = mode === EditModes.UPDATE;
  const isCreateMode = mode === EditModes.CREATE;

  const { isAllowedTo, isLoading } = useUserPermissionsContext();

  const isAllowedToUpdate = isAllowedTo(
    UserRights.UPDATE,
    FILTER_TYPES.cable_type
  );
  const isAllowedToDelete = isAllowedTo(
    UserRights.SOFT_DELETE,
    FILTER_TYPES.cable_type
  );
  const isAllowedToCreate = isAllowedTo(
    UserRights.CREATE,
    FILTER_TYPES.cable_type
  );

  const {
    cableType,
    fetchCableTypeData,
    isLoading: recordIsLoading,
  } = useCableType(cableTypeParams);

  const [fields] = useFields(
    FILTER_TYPES.cable_type,
    false,
    isCreateMode,
    isUpdateMode
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function storeCableType(payload) {
    if (isUpdateMode) {
      // Existing cableType
      await update({ ...payload, id: cableType.id }, { route: API_ROUTE }).then(
        () => {
          fetchCableTypeData(cableTypeParams);
        }
      );
    } else {
      // Brand new cableType
      return create(payload, { route: API_ROUTE }).then((res) => {
        history.push(["/cable-type", res.id].join("/"));
      });
    }
  }

  async function removeCableType(payload) {
    if (isUpdateMode) {
      return softDelete({ route: API_ROUTE, delete: true })(payload).then(
        () => {
          fetchCableTypeData(cableTypeParams);
        }
      );
    }
  }

  async function cancelChanges() {
    if (isUpdateMode) {
      fetchCableTypeData(cableTypeParams);
    } else {
      history.goBack();
    }
  }

  function restoreDeletedCableType(payload) {
    return softDelete({ route: API_ROUTE, delete: false })(payload).then(() => {
      fetchCableTypeData(cableTypeParams);
    });
  }

  return !fields || recordIsLoading ? (
    <Box
      sx={{
        marginTop: 3,
        textAlign: "center",
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Paper
      sx={{
        padding: 4,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "1rem",
        }}
      >
        <Typography variant='h5'>{title}</Typography>
        {isUpdateMode && (
          <CreateItemButton
            size='medium'
            entity={FILTER_TYPES.cable_type}
            to={ROUTES.cableType.create}
            isDisabled={isAllowedToCreate === false}
          />
        )}
      </Box>
      {isLoading === false && (
        <EditEntity
          {...{
            isAllowedToUpdate,
            isAllowedToDelete,
            isAllowedToCreate,
            entity: cableType,
            fields,
            storeEntity: storeCableType,
            deleteEntity: removeCableType,
            entityType: FILTER_TYPES.cable_type,
            cancel: cancelChanges,
            restoreEntity: restoreDeletedCableType,
            isUpdateMode,
          }}
        />
      )}
    </Paper>
  );
}
