import React, { useContext } from "react";
import compose from "compose-function";
import moment from "moment";

import TableComponent from "../../components/TableComponent";
import CreateItemButton from "../../components/CreateItemButton";

import { useColumns } from "../../hooks/useColumns";
import useUserPermissionsContext from "../../hooks/useUserPermissionsContext";

import {
  getSortOrder,
  sortColumns,
  sortModelToString,
  fetchDataWithOptionsAndFilters,
  exportToFile,
} from "../../utils/utils";

import {
  addHrefToRow,
  changeDeletedToBoolean,
  addValueFormatter,
  padValueWith,
  readDataFromLocalStorage,
  getDefaultFilterModel,
  getInitialFilters,
  generateEntityKeyForLocalStorage,
} from "../../utils/table";

import { hardDelete, softDelete } from "../../dao/operations";

import { exportRecords } from "../../dao/common";
import { FILTER_TYPES } from "../../dao/types";

import UserRights from "../../enums/UserRights";

import { ROUTES } from "../../enums/Routes";
import { API_ROUTES } from "../../enums/api";
import { addSearchParams } from "../../utils/dao";

import { GlobalContext } from "../../globalContext";
import useEntities from "../../hooks/useEntities";
import { useSortModel } from "../../hooks/useSortModel";

const API_ROUTE = API_ROUTES["system-groups"];
/**
 * Here we need to transform the row data retrieved,
 * in order to make it compatible with @mui/x-data-grid-pro
 */
const transformRows = (rows) => {
  return rows.reduce((acc, row) => {
    return [
      ...acc,
      compose(
        changeDeletedToBoolean("deleted"),
        addHrefToRow({
          field: "id",
          slug: "system-group",
        })
      )(row),
    ];
  }, []);
};

/**
 * Here we need to transform the column data retrieved,
 * in order to add @mui/x-data-grid-pro formatters
 */
const transformColumns = (columns) => {
  return columns.reduce((acc, column) => {
    return [
      ...acc,
      compose(
        addValueFormatter("system_group_number")(padValueWith(3)),
        addValueFormatter("number")(padValueWith(3)),
        addValueFormatter("object_number")(padValueWith(5))
      )(column),
    ];
  }, []);
};

export default function SystemGroupsContainer({
  nestedEntity = false,
  parent = {},
}) {
  const { globalFilters } = useContext(GlobalContext);
  const { sortModel, setSortModel } = useSortModel(FILTER_TYPES.system_group, {
    nestedEntity,
  });

  // Get cached local filter model from local storage
  // with a fallback to default field values
  const cachedLocalFilter = readDataFromLocalStorage(
    generateEntityKeyForLocalStorage(FILTER_TYPES.system_group, "filterModel", {
      nestedEntity,
    })
  ) ?? {
    filterModel: null,
    filterJSON: "",
  };

  // destructure fields from cachedLocalFilter
  const {
    filterModel: cachedLocalFilterModel,
    filterJSON: cachedFilterJSONString,
  } = cachedLocalFilter;

  const initialFilters = getInitialFilters({
    cache: {
      localFilterModel: cachedLocalFilterModel,
      filterJSONString: cachedFilterJSONString,
    },
    defaultFilterModel: getDefaultFilterModel("object_number").filterModel,
  });

  const { columns } = useColumns(FILTER_TYPES.system_group);

  const sortModelString = sortModelToString(sortModel, columns);

  const {
    data,
    loadingState,
    fetchEntitiesData: fetchSystemGroupsData,
    setFilterString,
    setOffset,
    limit,
    offset,
    setLimit,
    totalNumberOfRecords,
  } = useEntities("system-groups", {
    params: { object_ids: parent?.object_id ?? globalFilters?.value ?? null },
    initialFilters: initialFilters.apiFilterString,
    order: sortModelString,
  });

  const fetchRecordsDataWithGlobalFilters = fetchDataWithOptionsAndFilters(
    fetchSystemGroupsData
  )({
    limit,
    offset,
    order: sortModelString,
    params: {
      object_ids: parent?.object_id ?? globalFilters?.value ?? null,
    },
  });

  const fetchExportData = async (filterString, selectedColumnsString) => {
    const abortController = new AbortController();
    const searchParams = {
      filters: filterString,
      columns: selectedColumnsString,
      object_ids: parent?.object_id || globalFilters?.value,
    };
    const response = await exportRecords(
      API_ROUTE,
      searchParams,
      abortController
    );

    exportToFile(
      `export_${FILTER_TYPES.system_group}_overview_${moment().format(
        "DD-MM-YYYY"
      )}.csv`,
      response
    );

    return "Export done";
  };

  const { isAllowedTo } = useUserPermissionsContext();

  const isAllowedToCreate = isAllowedTo(
    UserRights.CREATE,
    FILTER_TYPES.system_group
  );
  const isAllowedToDelete = isAllowedTo(
    UserRights.SOFT_DELETE,
    FILTER_TYPES.system_group
  );
  const isAllowedToExport = isAllowedTo(UserRights.EXPORT_CSV);
  const isAllowedToInlineEdit = isAllowedTo(
    UserRights.INLINE_EDIT_SYSTEM_GROUP
  );

  const tableOptions = {};
  tableOptions.sortOrder = getSortOrder(FILTER_TYPES.system_group);
  tableOptions.hasDelete = isAllowedToDelete;
  tableOptions.hasInlineEdit = isAllowedToInlineEdit;

  const searchParams = new URLSearchParams(
    Object.entries({
      objectId: parent?.object_id ?? globalFilters?.value,
    }).filter(([, value]) => value)
  ).toString();

  return (
    <>
      <TableComponent
        {...{
          columns: transformColumns(sortColumns(columns)),
          transformColumns,
          loading: loadingState,
          rows: transformRows(data),
          refreshData: fetchRecordsDataWithGlobalFilters,
          renderCreateEntityButton: () => (
            <CreateItemButton
              to={addSearchParams(ROUTES.systemGroup.create)(searchParams)}
              isDisabled={!isAllowedToCreate}
              entity={FILTER_TYPES.system_group}
            />
          ),
          softDeleteEntry: softDelete({
            route: API_ROUTE,
            delete: true,
          }),
          hardDeleteEntry: hardDelete({
            route: API_ROUTE,
          }),
          restoreEntry: softDelete({
            route: API_ROUTE,
            delete: false,
          }),
          tableOptions,
          setFilters: setFilterString,
          setOffset,
          offset,
          setLimit,
          sortModel,
          setSortModel,
          numberOfRecords: totalNumberOfRecords,
          entityRoute: API_ROUTE,
          isAllowedToExport,
          entity: FILTER_TYPES.system_group,
          nestedEntity: nestedEntity,
          initialFilterModel: initialFilters.grid,
          exportRecords: fetchExportData,
        }}
      />
    </>
  );
}
